import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import ReactGA from 'react-ga4'
import AppComponent from './app'
import reportWebVitals from './report-web-vitals'

if (process.env.REACT_APP_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID)

  // Sends page view event
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname
  })

  // Saves the current app version in the user's properties.
  ReactGA.gtag('set', 'user_properties', {
    deployment_version: process.env.REACT_APP_DEPLOYMENT_VERSION
  })

  // Checks for internal developer.
  if (window.location.search.includes('internal')) {
    ReactGA.gtag('set', 'user_properties', {
      is_internal: true
    })
  }
}

const ROOT = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
ROOT.render(
  <React.StrictMode>
    <AppComponent />
  </React.StrictMode>
)
reportWebVitals()
