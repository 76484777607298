import React from 'react'
import ReactGA from 'react-ga4'

import './App.css'
import AmazethuLogo from './assets/images/Amazethu Logo.svg'
import Heading from './assets/images/Heading 1.svg'
import GlobeImage from './assets/images/globe-image2.png'

const WEBAPP_URI = 'https://learn.amazethu.com'

const AppComponent: React.FC = () => {
  const redirect = () => {
    const eventCallBackHanderProp = 'event_callback'
    return ReactGA.gtag('event', 'launch_demo', {
      event_category: 'Redirection',
      event_label: 'Landing Page to Web App',
      [eventCallBackHanderProp]: () => {
        const { search } = new URL(window.location.href)
        window.location.href = `${WEBAPP_URI}${search}`
      }
    })
  }
  return (
    <div className="app">
      <div className="top-navbar">
        <div className="nav-items">
          <div className="amazethu-logo">
            <img src={AmazethuLogo} className="icon" alt="" />
            <img src={Heading} className="icon-text" alt="" />
          </div>
          <div className="btn">
            <a href={WEBAPP_URI} onClick={redirect}>
              Launch Demo
            </a>
          </div>
        </div>
      </div>

      <div className="website-info">
        <div className="text-info">
          <h2 className="text1">Listen and Speak</h2>
          <p className="text2">
            Transform your language learning journey with our listen-and-speak
            interactive audio programs. You&apos;ll learn languages naturally
            and be able to speak with confidence in no time!
          </p>
          <div className="btn">
            <a href={WEBAPP_URI} onClick={redirect}>
              Launch Demo
            </a>
          </div>
        </div>
        <div className="globeImage">
          <img src={GlobeImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default AppComponent
